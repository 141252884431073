:root {
  font-family: circular;
  line-height: 1.5;
  font-weight: 400;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  /* color-scheme: light dark; */
  /* color: rgba(255, 255, 255, 0.87); */
  /* background-color: #242424; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --circle-size: 12vw;
  --cicler-icon-size: 2vw;
  --cirlce-inner-size: 6vw;
}

@font-face {
  font-family: "circular";
  src: url("https://cerclex-public-static-content.s3.ap-south-1.amazonaws.com/CircularStd-Book.otf");
}

@font-face {
  font-family: "circular-light";
  src: url("../src/fonts/circular/CircularStd-Light.otf");
}

@font-face {
  font-family: "circular-bold";
  src: url("https://cerclex-public-static-content.s3.ap-south-1.amazonaws.com/CircularStd-Bold.otf");
}

/*! To be added to global css */
.input-field-style {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 10px 10px;
  height: 54px;
  background-color: #fff;
  border: 1px solid #dadae7;
  margin-top: 1rem;
}

.block-chain-header {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  background: linear-gradient(to bottom, #2f1766, #7c45eb);
  color: #fff;
  border: 3.5px solid rgb(202, 180, 247);
}

.current-milestone{
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  margin-top: 10px;
}

.block-chain-lot {
  /* background-image: url("../public/images/lot-bg.png"); */
  /* background-color: #a43d74; */
  background-color: #0e1b35 !important;
}

.collapsible-block-chain {
  background-color: #efefef;
  background: linear-gradient(to bottom, #1d19337a, #d4207a56, #f36bab62);
}

.block-chin-sustainability {
  background-color: #653ac6;
  color: #fff;
  font-weight: 700;
  font-size: large;
  border: 3px solid #d3166e;
}

.blockchain-btn {
  /* background-color: #870a89; */
  border: 3px solid #ee6ff0;
  background: linear-gradient(to bottom, #8641807a, #653ac6, #653ac6);
}

.small-text {
  font-size: 10px !important;
}

.ml-2 {
  margin-left: 20px !important;
}

.title {
  font-size: 16px !important;
  font-weight: 900 !important;

}

.collapsible-header {
  padding: 0px !important;
  border-radius: 5px !important;
  height: 60px !important;

}

.normal-text {
  font-size: 12px !important;
}

[type=radio]:checked+span:after,
[type=radio].with-gap:checked+span:before,
[type=radio].with-gap:checked+span:after {
  border: 2px solid #6f2da8;
}

[type=radio]:checked+span:after {
  background-color: #6f2da8 !important;
}

.edit-btn {
  position: absolute;
  z-index: 2;
  background-color: #6f2da8;
  right: -5px;
  bottom: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cercle-btn {
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.search-input {
  height: 35px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cecece;
  width: 20vw;
  padding: 5px;
}

.upload-button {
  cursor: pointer;
  display: inline-block;
  padding: 1px 2px;
  border: none;
  margin-top: 1px;
}

.upload-img {
  width: 300px;
  height: 340px;
}

.phone-input-style {
  /* padding-left: 0% !important; */
  width: 88% !important;
}

.input-field-style>input {
  width: 100%;
  border: none;
  outline: none;
}

/*! to be added to global css */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "circular";
}

.dashboard-card-img {
  width: 50px;
  height: 50px;
}

.line1 {
  height: 5vh;
  border-radius: 15px;
  width: 100%;
  margin-top: 2px;
  border-radius: 60px;
  background: #ffffff;
  box-shadow: inset -7px -7px 42px #d2c6da, inset 2px 2px 22px #dbc9e4;
  margin-top: 8px;
  position: relative;
}

.semi-bold {
  color: #888;
}

/* .card-title {
  font-size: 1.2rem;
  font-weight: 600;
} */
.custom-modal {
  width: 80vw;
}

.semibold-notification {
  font-size: 14px;
  font-weight: 450;
  margin-bottom: 5px;
  color: #263238;
  line-height: 18px;
}

.chart-header {
  font-size: 1rem;
  color: #4f4f4f;
  line-height: 17px;
}

.bold {
  font-size: 1.2rem;
  font-weight: 700;
}

.bold-card-title {
  font-size: 1.4rem;
  font-weight: 700;
}

.box {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

.upload-file {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.circular-font {
  font-family: Arial, Helvetica, sans-serif;
}

.notifcation-unseen-background {
  background: #ebe0f466;
}

.filter-location {
  background-color: #fff;
  margin-top: 0.8rem;
  left: 15rem;
  position: absolute;
  padding: 16px;
  border-radius: 10px;
  width: 20vw;
}

.filter-location input {
  width: 100%;
  height: 20px;
}

.round-background {
  width: 80px;
  height: 80px;
  background-color: #ebe0f4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-background+input {
  position: absolute;
  width: 80px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
}

.user-profile {
  height: 100%;
  border-left: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 15vw;
}

.driver-assigned-toast {
  padding: 10px;
}

.header-drop-down {
  background-color: #fff;
  width: 20vw;
  height: 60%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 12px #fffefe;
  margin-right: 10px;
}

.default-toast {
  padding: 10px !important;
  min-height: fit-content !important;
  /* border: 1px solid #fff !important; */
}

.user-profile-drop-down {
  width: 20vw !important;
  top: -4rem;
  padding: 10px 0;
}

aside::-webkit-scrollbar {
  width: 0.4em;
}

aside::-webkit-scrollbar-thumb {
  background-color: #b289d5;
  outline: 1px solid #f7f7f7;
  border-radius: 20px;
}

.app-image {
  width: 40px;
}

.chart-date-picker {
  /* background: red; */
  color: #000 !important;
  height: 30px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.chart-date-picker input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.header-notification {
  width: 30vw !important;
  top: -2.5rem;
  padding-bottom: 2rem;
}

.countrol-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  color: #000;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: -1px 0px 7px #d4d4d4, 36px 36px 72px #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
}

.round-background i {
  font-size: 40px;
  color: #6f2da8;
}

.round-background-action {
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 40px;
  display: flex;
  justify-content: center;
  margin-left: -6px;
  color: #666;
}

.inputWrapper {
  height: 60px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /* background-color: #fff; */
  /* border: 1px solid #929292; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebe0f4;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.contract {
  width: 24vw;
}

.line2 {
  display: flex;
  flex-direction: row;
  animation-name: milestone;
  animation-duration: 1s;
}

.progress-rate {
  background: linear-gradient(180deg, #6f2da8 0%, #6d20afc4 47.4%);
  height: 5vh;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 60px;
  justify-content: center;
}

.vector-img {
  width: 5vh;
  height: 100%;
  object-fit: contain;
  margin-left: 5px;
}

.recent-pickup-card {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.raise-pickups-sidebar {
  width: 30%;
}

.dashboard-sidebar {
  width: 20%;
  height: 100%;
}

.main-dashboard {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
  /* padding-top: 10px; */
}

.group {
  background-image: url("../public/images/group-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 75%;
  height: 30vh;
  padding: 1rem 4rem;
}

a {
  color: #000;
}

.certificate {
  background-color: #fff;
  width: 70%;
  height: 80vh;
  background-image: url("../public/images/certificate-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#container_2 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.certificate-logo {
  width: 10vw;
  height: 20vw;
  background-color: #6f2da8;
}

.account-img {
  border: 4.2px #6f2da8 dashed;
  border-radius: 50%;
  justify-content: center;
  align-items: centerF;
  width: 120px;
  display: flex;
  height: 120px;
  padding: 6.5px;
  position: relative;
}

.certificate-content {
  width: 50%;
}

.input-style {
  background-color: #fff;
  width: 100%;
  padding: 0px 15px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #dadae7 !important;
  background: #fff;
  margin-top: 5px;
  outline: none;
  color: #666;
}

.no-margins {
  margin: 0 !important;
}

.verified {
  background-color: #2ECC71;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff
}

.input-style ::placeholder {
  color: #dadae7;
}

.input-style input {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #666;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  height: 5rem;
  box-shadow: 7px 0 20px 0 #dddddd;
  padding: 0 1rem;
  border: 1px solid rgba(221, 221, 221, 0.6);
}

.overlay {
  height: calc(100vh - 5rem);
  width: 100%;
  display: flex;
}

.full-frame {
  /* border: 10px solid red; */
  height: 100vh;
  /* overflow-y: auto; */
}

.aside-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 5rem;
}

.debug-border {
  border: 4px solid red;
}

.quantity-channelized-chart {
  height: max-content;
  min-height: 300px;
  width: 78%;
}

.aside-list>img {
  width: 3rem;
  height: 3rem;
  margin: 1rem 0;
  cursor: pointer;
}

.overlay>aside {
  height: 100%;
  box-shadow: 0 7px 20px 0 #dddddd;
  z-index: 1;
  background-color: #fff;
}

.overlay>section {
  overflow-y: auto;
  width: 100%;
  background-color: #f5f5f5;
  height: 100%;
}

.popup-card {
  background-color: red;
  width: 200px;
  height: 100px;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ddd;
  width: 79%;
}

.overlay .open-asid {
  padding-left: 14.5rem;
}

.aside-list-open {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 5rem);
  align-items: center;
  width: 13rem;
}

.aside-list-open>img {
  width: 3rem;
  height: 3rem;
  margin: 1rem 0;
  cursor: pointer;
}

.aside-items-list li {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin: 5px 0;
  color: #25174d;
  gap: 10px;
  font-size: 1.2rem;
  font-weight: 500;
}

.aside-items-list {
  width: 100%;
}

.aside-items-list li:hover {
  background-color: #6f2da8;
  color: #fff;
  cursor: pointer;
}

.overlay .opne-asid {
  /* padding-left: 14.5rem; */
}

.active-aside-items-list {
  background-color: #6f2da8;
  color: #fff !important;
}

/* auth */

.auth-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-from {
  max-width: 50vw;
  padding: 0 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
}

.auth-from img {
  width: 60px;
  height: 60px;
}

.auth-from img {
  width: 60px;
  height: 60px;
}

.input-field-style {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 10px 10px;
  height: 54px;
  background-color: #fff;
  border: 1px solid #dadae7;
  margin-top: 1rem;
}

.input-field-style>input {
  width: 100%;
  border: none;
  outline: none;
}

.scrap-image {
  width: 45px;
  height: 45px;
}

.raise-pickups {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.raise-pickups-section {
  background-color: #fff;
  width: 90%;
  border-radius: 10px;
  padding: 30px 20px !important;
  box-shadow: 1px 1px 2px rgb(197, 197, 197);
}

.time-line-page {
  width: 55vw;
}

.time-line {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.user-chip {
  background: #ececec;
  width: fit-content;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
}

.user-chip img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px 5px 0 0;
}

.time-line-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.time-line-left-step {
  width: 50vw;
}

.timeline-card {
  height: 15vh;
  width: 30%;
  position: absolute;
  top: 0;
  left: 49.3%;
  margin-top: 10px;
  /* background-color: red; */
}

.timeline-card>header {
  display: flex;
}

.timeline-card>header>span {
  display: block;
  width: 25px;
  height: 25px;
  background: green;
  border-radius: 50%;
}

.timeline-card>header>p {
  margin-left: 15px;
}

.timeline-body {
  /* background: blue; */
  width: 85%;
  height: 60%;
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0;
}

.timeline-body>div {
  height: 100%;
}

.timeline-body>div>span {
  display: block;
  width: 5px;
  height: 100%;
  background: green;
  border-radius: 5px;
}

.time-line-left-step {
  width: 50vw;
}

.update-box {
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
}

.timeline-container {
  /* background-color: red; */
  position: relative;

  height: 100vh;
}

.grey-line {
  width: 5px;
  height: 100vh;
  background: #717d96;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  /* z-index: 100; */
}

.loading-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  z-index: 2;
}

.loading-page img {
  width: 100px;
  height: 100px;
}

.line {
  width: 2px;
  background-color: #717d96;
  height: 80px;
}

.time-line {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.view-btn {
  background-color: #6f2da8;
  outline: none;
  border: none;
  width: 43%;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.time-line-box {
  background-color: #fff;
  border-radius: 5px;
  border-left: 4px solid #fff;
  box-shadow: 2px 2px 12px rgb(243, 243, 243);
  padding: 15px;
}

.selected-network {
  color: #fff;
  background: #6f2da8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-network2 {
  background: #fff;
  cursor: pointer;
}

.unselected-network2 {
  cursor: pointer;
}

.new-notification-indicator {
  background: tomato;
  background-color: #ff1493;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  height: 16px;
  position: absolute;
  top: 12px;
  /* right: 10px;   */

  width: 18px;
  text-align: center;
}

.dashboard-content {
  flex: 1;
  padding: 0 15px;
  margin-right: 21%;
}

.notification-item {
  background-color: #fff;
  border-bottom: 1px solid #dadae7;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  position: relative !important;
}

.icon {
  font-weight: bold;
}

.icon-bg {
  background-color: #f3ecf9;
  border-radius: 50%;
  color: #6f2da8;
  font-size: 2rem;
  height: 3rem !important;
  margin: 0;
  padding: 15px;
  width: 3rem !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.notification-description {
  color: #717d96;
  font-family: circular-light;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 18px;
  white-space: pre-line;
}

.unread-point {
  background-color: #ff1493;
  border-radius: 50%;
  height: 7.5px;
  margin-top: 5px;
  width: 7.5px;
}

.semi-bold-notification {
  color: #263238;
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
  margin-bottom: 5px;
}

.scrap-card {
  background-color: #fff;
  border-radius: 10px;
  border-left: 4px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.small-text {
  font-size: 0.9rem;
}

.notifcation-unseen-background:hover {
  background: #fff;
}

.notifcation-unseen-background {
  background: #ebe0f466;
}

.tableHover:hover {
  cursor: pointer;
  background: #ececec !important;
  color: #000 !important;
}

.lowercase {
  text-transform: lowercase;
}

.single-pickup-map {
  width: 90vw;
  border-radius: 10px;
}

.rounded {
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
  border: 1px solid #f0f0f0;

}

.first-time-container {
  height: 100vh;
}

.dialogue-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  border: 1px solid #ececec;
  /* margin-bottom: 10vh; */
  margin-top: 20px;
  max-height: 60vh;
}

.first-time-button {
  margin-bottom: 20vh;
}

.map-content {
  width: 30vw;
  padding: 20px;
  overflow-y: auto;
}

.add-location-container {
  border: 1px solid #ececec;
  display: flex;
}

.add-location-map {
  width: 30vw;
  position: relative;
  height: 42vh;
  display: flex;
}

.first-time-aside {
  background-color: #fff;
  width: 25%;
  padding: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.first-time-aside-list-container {
  /* background-color: blue; */
  height: 80%;
  margin-top: 10px;
}

.scrap-select {
  width: 24%;
  height: 150px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-right: 1%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
}

.scrap-selected {
  width: 23%;
  height: 150px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  background: #ebe0f4;
  margin-right: 1%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
}

.scrap-select>img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.scrap-selected>img {
  width: 100%;
  height: 60%;
  object-fit: cover;
  background: #fff;
}

.btn-rounded {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #ebe0f4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.order-box {
  min-width: 25%;
}

.order-container {
  /*Horizontal scrolling*/
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin: 10px 0;
}

.progress-container {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 10px 0;
}

/* The actual progress bar */
.progress-bar {
  width: 0;
  border-radius: 10px;
  height: 30px;
  background-color: #4caf50;
  text-align: center;
  line-height: 30px;
  color: white;
}

.custom-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(45deg,
      #3498db,
      #8e44ad);
  /* Gradient background */
  color: #fff;
}

.custom-badge p {
  font-size: 0.8em;
}

@media screen and (max-width: 1400px) {
  .time-line-left-step {
    width: 100%;
  }

  .time-line-page {
    width: 100%;
    padding: 20px;
  }

  .contract {
    width: 100%;
  }

  .group {
    width: 100%;
  }

  .overlay .open-asid {
    padding-left: 0;
  }

  .aside-list-open {
    background-color: #25174d;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  .certificate {
    width: 100%;
  }

  .dialogue-box {
    width: 90%;
  }
}

@media screen and (max-width: 1050px) {
  .overlay .opne-asid {
    padding: 20px;
    position: fixed;
  }

  .header-notification {
    width: 95vw !important;
    top: -2.5rem;
    left: 10px;
    padding-bottom: 2rem;
  }

  .auth-form {
    width: 100%;
  }

  .aside-list {
    display: none;
  }

  .overlay section {
    padding: 10px;
  }

  .main-dashboard {
    display: flex;
    flex-direction: column;
  }

  .filter-location {
    width: 90%;
    right: 0;
    left: 14px;
  }

  .certificate {
    font-size: 12px;
    padding: 0;
    background-image: none;
  }

  .certificate-content {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (max-width: 790px) {
  .auth-page {
    background-image: none;
  }

  .auth-from {
    min-width: 100vw;
    padding: 0 1.4rem;
  }

  .add-location-map {
    width: 100%;
    height: 80vh;
  }

  .add-location-container {
    flex-direction: column;
    /* background: red; */
    width: 100%;
    border: none;
  }

  .map-content {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }

  .add-location-map {
    width: 90vw;
    height: 50vh;
  }

  .first-time-aside {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
  }

  .dashboard-content {
    width: 100%;
    padding: 0;
  }

  .quantity-channelized-chart {
    width: 100%;
  }

  .scrap-select {
    width: 45%;
  }

  .scrap-selected {
    width: 45%;
  }
}

#file-input {
  display: none;
}


.vehicle-photo {
  border-radius: 5px;
  width: 160px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #efefef;
  margin-bottom: 10px;

}

.verify-btn {
  background-color: #4caf50;
  padding: 2px 5px;
  border-radius: 3px;
  color: #fff;
}

.reject-btn {
  background-color: tomato;
  padding: 2px 5px;
  border-radius: 3px;
  color: #fff;
}



/* circle */

.circle-1 {
  position: relative;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: #f0f0f0;
  /* Background color of the circle */
  box-shadow: inset 8px 8px 16px #d1d1d1, inset -8px -8px 16px #ffffff;
  /* Outer shadow and highlight */
  padding: 8px;
  /* Adjust the indentation by changing the padding */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.circle-2 {
  position: relative;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: #f0f0f0;
  /* Background color of the circle */
  box-shadow: inset 8px 8px 16px #d1d1d1, inset -8px -8px 16px #ffffff;
  /* Outer shadow and highlight */
  padding: 8px;
  /* Adjust the indentation by changing the padding */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.circle-3 {
  position: relative;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: #f0f0f0;
  /* Background color of the circle */
  box-shadow: inset 8px 8px 16px #d1d1d1, inset -8px -8px 16px #ffffff;
  /* Outer shadow and highlight */
  padding: 8px;
  /* Adjust the indentation by changing the padding */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.circle-4 {
  position: relative;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: #f0f0f0;
  /* Background color of the circle */
  box-shadow: inset 8px 8px 16px #d1d1d1, inset -8px -8px 16px #ffffff;
  /* Outer shadow and highlight */
  padding: 8px;
  /* Adjust the indentation by changing the padding */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.circle-active {
  /* width: calc(var(--circle-size) + 3vw);
  height: calc(var(--circle-size) + 3vw); */
  background: rgba(226, 195, 253, 0.8);
}

.circle-active .circle-inner {
  /* width: calc(var(--cirlce-inner-size) + 1.5vw);
  height: calc(var(--cirlce-inner-size) + 1.5vw); */
  /* background: rgba(235, 224, 244, 0.8); */
}

.circle-active .circle-inner>p {
  color: #6f2da8;
  font-family: circular-bold;
}

.circle-active .circle-icon-1,
.circle-active .circle-icon-2,
.circle-active .circle-icon-3,
.circle-active .circle-icon-4,
.circle-active .circle-icon-5,
.circle-active .circle-icon-6,
.circle-active .circle-icon-7,
.circle-active .circle-icon-8,
.circle-active .circle-icon-9 {
  border-radius: 50% !important;
  /* width: calc(var(--cicler-icon-size) + 1vw); */
  /* height: calc(var(--cicler-icon-size) + 1vw); */
}

.circle-inner {
  width: var(--cirlce-inner-size);
  height: var(--cirlce-inner-size);
  border-radius: 50%;
  background-color: #f0f0f0;
  /* Background color of the circle */
  box-shadow: 8px 8px 16px #d1d1d1, -8px -8px 16px #ffffff;
  /* Outer shadow and highlight */
  padding: 8px;
  /* Adjust the indentation by changing the padding */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.circle-icon-1 {
  position: absolute;
  top: 5%;
  background-color: lightblue;
  /* width: var(--cicler-icon-size);
  height: var(--cicler-icon-size); */
  width: 50px;
  height: 50px;
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.circle-icon-2 {
  position: absolute;
  top: 15%;
  left: 15%;
  background-color: lightsteelblue;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);

}



.circle-icon-3 {
  position: absolute;
  top: 38%;
  left: 5%;
  background-color: teal;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.circle-icon-4 {
  position: absolute;
  top: 15%;
  left: 70%;
  background-color: pink;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.circle-icon-5 {
  position: absolute;
  top: 38%;
  left: 80%;
  background-color: lightcoral;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.circle-icon-6 {
  position: absolute;
  top: 60%;
  left: 75%;
  background-color: lightseagreen;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.circle-icon-7 {
  position: absolute;
  top: 75%;
  left: 60%;
  background-color: lightgray;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);

}

.circle-icon-8 {
  position: absolute;
  top: 78%;
  left: 30%;
  background-color: lightskyblue;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.circle-icon-9 {
  position: absolute;
  top: 60%;
  left: 10%;
  background-color: lightcyan;
  width: var(--cicler-icon-size);
  height: var(--cicler-icon-size);
  transition: all 0.5s ease-in-out;
  border-radius: 50% !important;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
}

.circle-1 span,
.circle-2 span,
.circle-3 span,
.circle-4 span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.box {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

.bottom-sheet-modal {
  margin: 0 !important;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  animation: slide-up 0.3s ease;
  padding: 0;
  padding-top: 25px;
  overflow: hidden;
}

.blurred-text {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}


.input-field-style {
  margin-top: 0 !important;
}




@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.modal-content {
  padding: 20px;
}

/* Add more styles as needed */

/* for very large screens */

@media (min-width: 1600px) {
  :root {
    /* cirlce size as 15vw or 20 rem, whichever is smaller*/
    --circle-size: min(15vw, 20rem);
    --cicler-icon-size: min(3rem, 3vw);
    --cirlce-inner-size: min(10rem, 7.5vw);
  }
}

/* for small screens */
@media (max-width: 600px) {
  .arrow-img {
    display: none;
  }

  :root {
    --circle-size: min(80vw, 20rem);
    --cicler-icon-size: min(5rem, 10vw);
    --cirlce-inner-size: min(40vw, 10rem);
  }

  .hero h1 {
    font-size: 2rem;
    margin-bottom: 0rem !important;
  }

  .hero h5 {
    font-size: 1rem;
    margin-bottom: 0rem !important;
  }

  .info-card {
    width: 40%;
  }

  .search-form {
    flex-wrap: wrap;
  }

  .table-section {
    display: none;
  }

  .table-container {
    position: fixed;
    width: 100%;
  }
}

/* progress bar */
.skill-main {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skill-per {
  height: 20px;
  background: #23576f;
  border-radius: 8px;
  width: 0;
  transition: 1s linear;
  position: relative;

  &:before {
    content: attr(per);
    position: absolute;
    padding: 4px 6px;
    background-color: #23576f;
    color: #fff;
    font-size: 11px;
    border-radius: 4px;
    top: -35px;
    right: 0;
    transform: translateX(50%);
  }

  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #23576f;
    top: -20px;
    right: 0;
    transform: translateX(50%) rotate(45deg);
    border-radius: 2px;
  }
}

/* test */
.Piece {
  & {
    position: relative;
    background-color: #4682b4;
    width: 150px;
    height: 75px;
  }

  &-phrase {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: white;
  }
}